export type InnerItemMenuType = {
  title: string;
  link?: string;
  number?: string;
  innerItems?: { title: string; link: string }[];
};
export type ContentMenuType =
  | {
      title: string;
      items: InnerItemMenuType[];
      link?: string;
    }
  | {
      title: string;
      link: string;
      items?: undefined;
    };

export const navigationLinks: ContentMenuType[] = [
  {
    title: "לקראת רכישה",
    items: [
      {
        title: "אולמות תצוגה",
        link: "https://www.bmw.co.il/he/topics/about-us/dealer-locator.html",
      },
      {
        title: "מחירון",
        link: "https://www.bmw.co.il/content/dam/bmw/marketIL/bmw_co_il/Price_lists/aug2024/38536%20Mechiron%20BMW%20Site%202024.pdf.asset.1722459103441.pdf",
      },
      {
        title: "מחשבון מימון",
        link: "https://www.bmw.co.il/he/test-pool/IL-finance-locator.html",
      },
      {
        title: "רכבים זמינים במלאי",
        link: "https://www.bmw.co.il/he/stocklocator.html",
      },
      {
        title: "תיאום נסיעת מבחן",
        link: "https://www.bmw.co.il/he/fastlane/Request_a_test_drive.html",
      },
      {
        title: "צור קשר - מכירות",
        link: "https://www.bmw.co.il/he/fastlane/request-for-information.html",
      },
      {
        title: "תקנון הטבת מימון וטרייד אין",
        link: "https://www.bmw.co.il/content/dam/bmw/marketIL/bmw_co_il/legal/apr2024/Legal_BMW_April.pdf.asset.1712216006177.pdf",
      },
    ],
  },
  {
    title: "שירות",
    items: [
      {
        title: "צור קשר",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/Contact-us.html",
      },
      {
        title: "מרכזי שירות מורשים",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/service-center.html",
      },
      {
        title: "קריאה חוזרת (Recall)",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/Recall.html",
      },
      {
        title: "מחירון מוצרי תעבורה",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/parts-price-list.html",
      },
      {
        title: "בדיקת קוד צבע",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/color-codes.html",
      },
      {
        title: "הוראות שירות",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/service-instructions-bmw.html",
      },
      {
        title: "הארכת אחריות",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/WarrantyService.html",
      },
      {
        title: "הטבת רכב חשמלי",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/rental-car-benefit.html",
      },
      {
        title: "BMW Excellence",
        link: "https://www.bmw.co.il/he/topics/offers-and-services/BMW_Excellence.html",
      },
      {
        number: "*3567",
        title: "מוקד טלפוני: ",
        link: "tel:*3567",
      },
    ],
  },
  {
    title: "טרייד אין",
    link: "/",
  },
  {
    title: "העולם שלנו",
    items: [
      {
        title: "על היסטוריה ומסורת",
        innerItems: [
          {
            title: "על היסטוריה ומסורת",
            link: "https://www.bmw.co.il/he/topics/fascination-bmw/heritage.html",
          },
        ],
      },
      {
        title: "Electromobility",
        innerItems: [
          {
            title: "מכוניות חשמליות",
            link: "https://www.bmw.co.il/he/electric-cars.html",
          },
          {
            title: "פלאג-אין הייבריד",
            link: "https://www.bmw.co.il/he/electric-cars/plug-in-hybrid.html",
          },
          {
            title: "טעינה",
            link: "https://www.bmw.co.il/he/electric-cars/electric-car-charging.html",
          },
          {
            title: "טווח",
            link: "https://www.bmw.co.il/he/electric-cars/electric-car-range.html",
          },
        ],
      },
      {
        title: "רכבי קונספט",
        innerItems: [
          {
            title: "BMW Vision Neue Klasse",
            link: "https://www.bmw.co.il/he/more-bmw/concept-cars/bmw-neue-klasse-overview.html",
          },
          {
            title: "i Vision Dee",
            link: "https://www.bmw.co.il/he/topics/fascination-bmw/bmw-concept-vehicle/bmw-vision-ivisiondee-2023.html",
          },
          {
            title: "i Vision Circular",
            link: "https://www.bmw.co.il/he/topics/fascination-bmw/bmw-concept-vehicle/bmw-i-vision-circular-highlights.html",
          },
          {
            title: "BMW Vision Neue Klasse X",
            link: "https://www.bmw.co.il/he/more-bmw/concept-cars/vision-neue-klasse-x.html",
          },
          {
            title: "BMW Vision Neue Klasse: סקירה",
            link: "https://www.bmw.co.il/he/more-bmw/neue-klasse.html",
          },
        ],
      },
      {
        title: "מן התקשורת",
        innerItems: [
          {
            title: "כתבות ומבחני דרכים",
            link: "https://www.bmw.co.il/he/fastlane/bmw-press.html",
          },
        ],
      },
      {
        title: "שירות",
        innerItems: [
          {
            title: "סרטוני הדרכה",
            link: "https://www.bmw.co.il/he/topics/fascination-bmw/how-to.html",
          },
          {
            title: "שירות הלקוחות של BMW",
            link: "https://www.bmw.co.il/he/more-bmw/%D7%A9%D7%99%D7%A8%D7%95%D7%AA-%D7%9C%D7%A7%D7%95%D7%97%D7%95%D7%AA.html",
          },
        ],
      },
      {
        title: "Connected Drive",
        innerItems: [
          {
            title: "שאלות ותשובות",
            link: "https://www.bmw.co.il/he/topics/offers-and-services/BMW-ConnectedDrive.html",
          },
        ],
      },
    ],
  },
];
