export const keepOnlyUtmParams = (search: string): string => {
  if (!search) return "";
  const params = search.split("&");
  return (
    params
      .filter(
        (param) =>
          param.includes("utm") ||
          param.includes("description") ||
          param.includes("audiences") ||
          param.includes("creative") ||
          param.includes("gclid") ||
          param.includes("fbclid") ||
          param.includes("gad_source") ||
          param.includes("creative") ||
          param.includes("audiences")
      )
      .join("&")
      // remove ? if it is the first character
      .replace(/^\?/, "")
  );
};
export const getUtmParamsAsKeyValueList = (): {
  key: string;
  value: string;
}[] => {
  // Extract query parameters from both `window.location.search` and `window.location.hash`
  const getQueryFromHash = () => {
    const hash = window.location.hash;
    const queryIndex = hash.indexOf("?");
    return queryIndex !== -1 ? hash.substring(queryIndex + 1) : "";
  };

  // Combine `window.location.search` and query params from the hash
  let search =
    keepOnlyUtmParams(window.location.search) ||
    keepOnlyUtmParams(getQueryFromHash());
  if (!search) return [];

  // Remove the first character if it is '?'
  if (search[0] === "?") {
    search = search.slice(1);
  }

  // Split and parse the parameters
  const params = search.split("&");
  return params.map((param) => {
    const [key, value] = param.split("=");
    return { key, value: decodeURI(value || "") };
  });
};
