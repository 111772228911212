import { ContentMenuType, navigationLinks } from "@/common/navigationLinks";
import BMWIcon from "@/components/shared/icons/BMWIcon";
import CloseIcon from "@/components/shared/icons/CloseIcon";
import NextLink from "@/components/shared/NextLink";
import styled from "@emotion/styled";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import dynamic from "next/dynamic";
import Image from "next/image";
import { Dispatch, SetStateAction, useState } from "react";

const StyledLinks = styled.a`
  text-decoration: none;
  color: black;
  :visited {
    color: black;
  }
  &:hover {
    color: #1c69d4;
  }
`;

const StyledSuspendedNextLink = styled(NextLink)`
  text-decoration: none;
  color: black;
  :visited {
    color: black;
  }
  &:hover {
    color: #1c69d4;
  }
`;

const MobileInnerDrawer = dynamic(() => import("./MobileInnerDrawer"));
const MobileMenuDrawer = ({
  toggleDrawer,
  openInner,
  setOpenInner,
}: {
  toggleDrawer: () => void;
  openInner: boolean;
  setOpenInner: Dispatch<SetStateAction<boolean>>;
}) => {
  const [selectedInnerData, setSelectedInnerData] =
    useState<ContentMenuType | null>(null);
  const toggleInnerDrawer =
    (nav: ContentMenuType | null, newOpen: boolean) => () => {
      setSelectedInnerData(nav);
      setOpenInner(newOpen);
    };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0 24px",
          alignItems: "center",
          borderBottom: "1px solid #E6E6E6",
          height: "68px",
        }}
      >
        <a
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          href="https://www.bmw.co.il/he"
        >
          <BMWIcon />
        </a>
        <Box>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ padding: "10px" }}>
        <List>
          <ListItem onClick={toggleDrawer} sx={{ textAlign: "right" }}>
            <StyledSuspendedNextLink href="/inventory">
              <Typography>כל הדגמים</Typography>
            </StyledSuspendedNextLink>
          </ListItem>
          <ListItem>
            <StyledLinks href="https://www.bmw.co.il/he/electric-cars.html">
              <Typography>דגמים חשמליים</Typography>
            </StyledLinks>
          </ListItem>
          {navigationLinks.map((nav, i) => (
            <>
              {nav.items ? (
                <ListItem
                  sx={{ display: "flex", justifyContent: "space-between" }}
                  key={i}
                  onClick={toggleInnerDrawer(nav, true)}
                >
                  <Typography>{nav.title}</Typography>
                  <Image
                    src="/assets/arrowLeft.svg"
                    alt="arrow"
                    width={24}
                    height={24}
                  />
                </ListItem>
              ) : (
                <ListItem onClick={toggleDrawer} key={i}>
                  <StyledSuspendedNextLink href={nav.link}>
                    <Typography>{nav.title}</Typography>
                  </StyledSuspendedNextLink>
                </ListItem>
              )}

              <Drawer
                hideBackdrop
                sx={{ top: "75px" }}
                PaperProps={{
                  sx: { width: "100%", top: "68px", boxShadow: "none" },
                }}
                anchor="right"
                open={openInner}
                onClose={toggleInnerDrawer(null, false)}
              >
                <MobileInnerDrawer
                  toggleInnerDrawer={toggleInnerDrawer}
                  contentMenu={selectedInnerData}
                />
              </Drawer>
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default MobileMenuDrawer;
