import Link, { LinkProps } from "next/link";
import { useSearchParams } from "next/navigation";
import { CSSProperties, ReactNode, Suspense } from "react";
import { UrlObject } from "url";
import { keepOnlyUtmParams } from "./utmParams";
interface NextLinkProps extends LinkProps {
  children?: ReactNode;
  openInNewTab?: boolean;
  style?: CSSProperties;
}

const NextLink = ({
  href,
  children,
  style,
  openInNewTab,
  ...props
}: NextLinkProps) => {
  const queryParams = useSearchParams();

  // Separate the URL into pathname, query params, and hash
  const url = typeof href === "string" ? href : "";
  const [pathname, queryString] = url.split("?");
  const [basePath, hash] = pathname.split("#");

  // Get only UTM parameters from the current URL
  const filteredQueryParams = keepOnlyUtmParams(queryParams.toString());

  // Manually construct the final href object
  const link: UrlObject = {
    pathname: basePath, // Use the pathname before hash
    search: filteredQueryParams
      ? `?${filteredQueryParams}${queryString ? "&" + queryString : ""}` // Append filtered query params and the existing queryString
      : queryString
        ? `?${queryString}`
        : "",
    hash: hash || "", // Ensure hash is directly passed without encoding
  };

  return (
    <Link
      target={openInNewTab ? "_blank" : "_self"}
      href={link}
      style={style}
      {...props}
    >
      {children}
    </Link>
  );
};

const SuspendedNextLink = (props: NextLinkProps) => (
  <Suspense fallback={<Link {...props} />}>
    <NextLink {...props} />
  </Suspense>
);

export default SuspendedNextLink;
